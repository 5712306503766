export const ICONS = [
  {
    img: "images/icons/hot-icon1.png",
  },
  {
    img: "images/icons/hot-icon2.png",
  },
  {
    img: "images/icons/hot-icon3.png",
  },
  {
    img: "images/icons/hot-icon4.png",
  },
  {
    img: "images/icons/hot-icon5.png",
  },
  {
    img: "images/icons/hot-icon6.png",
  },
  {
    img: "images/icons/hot-icon7.png",
  },
  {
    img: "images/icons/hot-icon3.png",
  },
  {
    img: "images/icons/hot-icon2.png",
  },
  {
    img: "images/icons/hot-icon4.png",
  },
  {
    img: "images/icons/hot-icon5.png",
  },
  {
    img: "images/icons/hot-icon6.png",
  },
  {
    img: "images/icons/hot-icon7.png",
  },
  {
    img: "images/icons/hot-icon1.png",
  },
  {
    img: "images/icons/hot-icon2.png",
  },
  {
    img: "images/icons/hot-icon6.png",
  },
  {
    img: "images/icons/hot-icon7.png",
  },
  {
    img: "images/icons/hot-icon3.png",
  },
  {
    img: "images/icons/hot-icon2.png",
  },
  {
    img: "images/icons/hot-icon4.png",
  },
];
