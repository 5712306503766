export const BRANCH_LIST = [
  {
    id: 1,
    name: "Trần bình trọng",
    lat: 10.842013351124288,
    lng: 106.6563940432175,
    location: "57 Trần bình trọng, Bình thạnh, Gò vấp",
    phone: "090303009",
    time: "Giờ mở cửa: 10:30 - 23:00",
  },
  {
    id: 2,
    name: "Lê quang định",
    lat: 10.843894598087104,
    lng: 106.64923800000001,
    location: "57 Trần bình trọng, Bình thạnh, Gò vấp",
    phone: "090303009",
    time: "Giờ mở cửa: 10:30 - 23:00",
  },
];
