export const LIST_DRINKS = [
  {
    title: "trà đá",
    thumbnail: "/images/data/drink/d1.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò, rau thơm
    `,
    detail: "",
    price: "57.000",
  },
  {
    title: "trà tắc",
    thumbnail: "/images/data/drink/d2.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, ch ả giò, rau thơm`,
    detail: "",
    price: "69.000",
  },
  {
    title: "Nước mơ",
    thumbnail: "/images/data/drink/d3.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, ch ả giò, rau thơm`,
    detail: "",
    price: "69.000",
  },
  {
    title: "Nước sấu",
    thumbnail: "/images/data/drink/d4.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, ch ả giò, rau thơm`,
    detail: "",
    price: "69.000",
  },
  {
    title: "Pepsi",
    thumbnail: "/images/data/drink/d5.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, ch ả giò, rau thơm`,
    detail: "",
    price: "69.000",
  },
  {
    title: "coca",
    thumbnail: "/images/data/drink/d6.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, ch ả giò, rau thơm`,
    detail: "",
    price: "69.000",
  },
  {
    title: "SPRITE",
    thumbnail: "/images/data/drink/d7.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, ch ả giò, rau thơm`,
    detail: "",
    price: "69.000",
  },
  {
    title: "nƯỚC SUỐI",
    thumbnail: "/images/data/drink/d8.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, ch ả giò, rau thơm`,
    detail: "",
    price: "69.000",
  },
];
