export const LIST_EX_FOODS = [
  {
    title: "Nem chua rán",
    thumbnail: "/images/data/ex_food/e1.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò, rau thơm`,
    detail: "",
    price: "56.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Chả giò",
    thumbnail: "/images/data/ex_food/e2.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò,
      rau thơm`,
    detail: "",
    price: "75.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "thịt luộc",
    thumbnail: "/images/data/ex_food/e3.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò,
      rau thơm`,
    detail: "",
    price: "42.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Đậu hũ",
    thumbnail: "/images/data/ex_food/e4.png",
    des: `(Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò, chả lụa, chả cua, chả ốc, rau thơm`,
    detail: "",
    price: "69.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Chả cốm",
    thumbnail: "/images/data/ex_food/e5.png",
    des: `(Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò, chả lụa, chả cua, chả ốc, rau thơm`,
    detail: "",
    price: "69.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Chả ốc",
    thumbnail: "/images/data/ex_food/e6.png",
    des: `(Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò, chả lụa, chả cua, chả ốc, rau thơm`,
    detail: "",
    price: "69.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Rau thơm",
    thumbnail: "/images/data/ex_food/e7.png",
    des: `(Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò, chả lụa, chả cua, chả ốc, rau thơm`,
    detail: "",
    price: "10.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Bún lá",
    thumbnail: "/images/data/ex_food/e8.png",
    des: `(Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò, chả lụa, chả cua, chả ốc, rau thơm`,
    detail: "",
    price: "19.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Cà Pháo Muôi",
    thumbnail: "/images/data/ex_food/e9.png",
    des: `(Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò, chả lụa, chả cua, chả ốc, rau thơm`,
    detail: "",
    price: "39.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
];
